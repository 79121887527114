@keyframes gradient {
    0% {background-position: 0% 50%}
    50% {background-position: 100% 50%}
    100% {background-position: 0% 50%}
  }
  
  .gradient-text {
    background: black;
    background-size: 200% 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient 2s ease infinite;
  }

  .gradient-text:hover {
    font-weight: bold;
    background: linear-gradient(90deg, #2C9942, #94BF31);
    background-size: 200% 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient 2s ease infinite;
  }